<template>
<ValidationObserver
  ref="edit_table_form"
  slim
  v-slot="{ handleSubmit, reset }"
  >
    <form
      class="edit_table_form"
      @submit.prevent="handleSubmit(updateTable)"
      @reset.prevent="reset"
      autocomplete="off"
    >
      <div class="form_fields">
        <div class="form_btns top_btns">
            <button type="button" class="secondary_btn" @click="cancelChanges">Cancel</button>
            <button aria-label="add section" class="primary_btn">Save</button>
        </div>

        <div class="field_block">
          <ValidationProvider
              name="seats"
              v-slot="{ errors }"
              slim
              rules="required"
            >

            <label v-if="editTable.type === 'round' || editTable.type === 'square'" :class="{ has_error: errors[0] }">
              <span class="field_name">Seats</span>
              <v-select
                v-if="editTable.type === 'square'"
                v-model="editData.seats"
                :options="tableOptions[editTable.type]"
                :reduce="(seats) => seats"
                @input="editTableHelper"
                :searchable="false"
                :clearable="false"
              ></v-select>

              <input
                v-else
                name="seats"
                type="text"
                oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                @input="debouncedUpdateTable"
                v-model="editData.seats"
              />
              <span class="error_message">{{
                    errors[0] ? "Required field" : ""
                }}</span>
            </label>

            <div v-else-if="editTable.type === 'rectangle'" class="select_group">
              <label>
                <span class="field_name">Seats by length</span>
                <v-select v-model="editData.sides[0]"
                          :options="tableOptions[editTable.type]"
                          :reduce="(materials) => materials"
                          :searchable="false"
                          :clearable="false"
                          @input="updateRectangeSelects">
                </v-select>
              </label>
              <label>
                <span class="field_name">Seats by width</span>
                <v-select v-model="editData.sides[1]"
                          :options="filteredSecondSelectOptions"  
                          :reduce="(materials) => materials"
                          :searchable="false"
                          :clearable="false"
                          @input="updateRectangeSelects">
                </v-select>
              </label>
            </div>
          </ValidationProvider>
        </div>

        <span class="divider"></span>

        <div class="field_block">
          <ValidationProvider
              name="name"
              v-slot="{ errors }"
              slim
              rules="required"
          >
            <label :class="{ has_error: errors[0] }">
              <span class="field_name">Name</span>
              <textarea name="name" rows="2" maxlength="20" v-model="editData.name" @input="editTableHelper"></textarea>
              <span class="error_message">{{
                  errors[0] ? "Required field" : ""
              }}</span>
            </label>
          </ValidationProvider>
        </div>
        <div class="field_block">
            <div class="check_group">
              <label>
                <input
                  type="checkbox"
                  @change="displayTitle"
                  v-model="editData.hideTitle"
                />
                <span class="check_btn">Hide table name</span>
              </label>
            </div>
          </div>

        <div class="field_block">
                    <span class="field_name">Font Size <span v-if="editData.fontSize !== 0"> ({{ Math.round(editData.fontSize * 100) / 100 }})</span></span>
                    <range-slider class="slider" min="14" max="40" step="1" v-model="editData.fontSize" @input="editTableHelper">
                    </range-slider>
                </div>
                <div class="field_block">
                    <span class="field_name">Line Height <span v-if="editData.lineHeight !== 0"> ({{ Math.round(editData.lineHeight * 100) / 100 }})</span></span>
                    <range-slider class="slider" min="1" max="2" step="0.1" v-model="editData.lineHeight" @input="editTableHelper">
                    </range-slider>
                </div>
                <div class="field_block">
                    <span class="field_name">Letter Spacing <span v-if="editData.spacing !== 0"> ({{ Math.round(editData.spacing * 100) / 100 }})</span></span>
                    <range-slider class="slider" min="-1" max="5" step="0.5" v-model="editData.spacing" @input="editTableHelper">
                    </range-slider>
                </div>

                <span class="divider"></span>
                <div class="field_block half_field">
                    <div class="check_group">
                        <label>
                            <input
                                type="checkbox"
                                @change="editTableHelper"
                                v-model="editData.upperCase"
                            />
                            <span class="check_btn">Uppercase</span>
                        </label>
                    </div>
                </div>
                <div class="field_block half_field">
                    <div class="check_group">
                        <label>
                            <input
                                type="checkbox"
                                @change="editTableHelper"
                                v-model="editData.bold"
                            />
                            <span class="check_btn">Bold</span>
                        </label>
                    </div>
                </div>
                <span class="divider"></span>


        <div class="field_block">
          <span class="field_name">Rotation <span v-if="editData.rotation !== 0"> ({{ Math.round(editData.rotation * 100) / 100 }})</span></span>
          <range-slider
            class="slider"
            min="-180"
            max="180"
            step="1"
            v-model="editData.rotation"
            @input="changeRotation"
          >
          </range-slider>
        </div>

        <div v-if="editTable.type === 'rectangle'" class="field_block">
          <span class="field_name">Table Width <span v-if="editData.width !== 100"> ({{ Math.round(editData.width * 100) / 100 }})</span></span>
          <range-slider
            class="slider"
            :min="calculatedMin"
            :max="calculatedMax"
            step="5"
            v-model="editData.width"
            @input="editTableHelper"
          >
          </range-slider>
        </div>

        <span class="divider"></span>

        <!-- <div class="form_btns">
          <button class="secondary_btn" @click="cancelChanges">Cancel</button>
          <button class="primary_btn" @click="updateTable">Submit</button>
        </div> -->
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import { mapGetters } from "vuex";
import RangeSlider from "vue-range-slider";

import {
  updateTableStage,
  rotateTable,
  cancelTableChanges,
  showHideTitle
} from "@/helpers/table/generator";

import {
  deselectGroup,
unFreezeAllGroups
} from "@/helpers/utils";
import {
  deselectGroupSeats
} from "@/helpers/seatUtils";
import { saveChanges } from '@/helpers/table/generator';
import debounce from 'lodash/debounce';
export default {
  props: {
    editTable: {
      type: Object,
      default: () => {},
    },
  },
  watch: {},
  data() {
    return {
      editData: {},
      maxSeats: 24
    };
  },
  created() {
    this.editData = _.cloneDeep(this.editTable);
  },
  methods: {
    editTableHelper() {
      const { layerId, type } = this.editTable;
      let inputValue = !isNaN(this.editData.seats) ? parseFloat(this.editData.seats) : false;
      let cappedValue;
      if (type === "round") {
        cappedValue = inputValue ? Math.min(inputValue, 16) : '';
      } else if (type === "square") {
        const restictValue = !inputValue ? 8 :
          inputValue % 4 === 0 ? inputValue
          : inputValue % 4 <= 2 ? inputValue - (inputValue % 4) 
          : inputValue - (inputValue % 4) + 4
        cappedValue = Math.min(restictValue, 24);
      } else if (type === "rectangle") {
        cappedValue = this.editData.sides[0] + this.editData.sides[1];
        
      }

      this.editData.seats = cappedValue;

      const payload = {
        tableData: this.editTable,
        newData: this.editData,
      };
    
      updateTableStage(layerId, payload);

    },

    debouncedUpdateTable: debounce(function() {
      this.editTableHelper();
    }, 300),
    updateTable() {
      const { layerId } = this.editTable;
      
      deselectGroupSeats(layerId);
      deselectGroup();
      saveChanges(layerId);
      this.$emit("closeModal");
      unFreezeAllGroups();
    },
    changeRotation() {
      rotateTable(
        this.editData.rotation,
        this.editTable.layerId,
        this.editData
      );
    },
    cancelChanges() {
      cancelTableChanges(this.editTable)
      this.$emit("cancel", this.editTable);
      unFreezeAllGroups();
    },
    displayTitle(){
      const { hideTitle } = this.editData;
      const { layerId } = this.editTable;
      showHideTitle({ hideTitle, layerId });
    },

    updateRectangeSelects() {
      
      const remainingSeats = this.maxSeats - this.editData.sides[0];
      if (this.editData.sides[1] > remainingSeats) {
        this.editData.sides[1] = remainingSeats; 
      }

      this.editData.width = this.editData.sides[1] !== 0 ? this.editData.sides[1] / 2 * 45 - 10 : 100
      this.editTableHelper();
    }
  },
  components: {
    RangeSlider,
  },
  computed: {
    ...mapGetters(["tableOptions"]),
     filteredSecondSelectOptions() {
      const remainingSeats = this.maxSeats - this.editData.sides[0];
      return [0, ...this.tableOptions[this.editTable.type].filter(option => option <= remainingSeats)];
    },
    calculatedMin() {
      return this.editData.sides[1] > 0 ? this.editData.sides[1] / 2 * 45 - 10 : 35;
    },
    calculatedMax() {
      return this.editData.sides[1] > 0 ? this.editData.sides[1]  * 45 - 10 + 100 : 200;
    }
  },
};
</script>

<style></style>
